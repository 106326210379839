import React, { useEffect, useState } from 'react'
import { Drawer, Row, Col, Image, List, Spin, Input } from "antd";
import VirtualList from 'rc-virtual-list';
import { download } from "@/api/sys";
import "@/assets/scss/vis.scss";
import { getTransactionList } from "@/api/acc";
import { FileImageOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
// 访客详情
export default function VisitorDetailsDrawer(props) {
  const { t, i18n } = useTranslation();
  const item = [
    {
      key: 'visitorName',
      label: t('components.VisitorDetailsDrawer.visitorName'),
    },
    {
      key: 'visitorPhone',
      label: t('components.VisitorDetailsDrawer.visitorPhone'),
    },
    {
      key: 'visitorCompanyName',
      label: t('components.VisitorDetailsDrawer.visitorCompanyName'),
    },
    {
      key: 'visitReason',
      label: t('components.VisitorDetailsDrawer.visitReason'),
    },
    {
      key: 'visitDate',
      label: t('components.VisitorDetailsDrawer.visitDate'),
    },
    {
      key: 'visitEndDate',
      label: t('components.VisitorDetailsDrawer.visitEndDate'),
    },
    {
      key: 'accessGroupName',
      label: t('components.VisitorDetailsDrawer.accessGroupName'),
    },
    {
      key: 'visitEmpName',
      label: t('components.VisitorDetailsDrawer.visitEmpName'),
    },
    {
      key: 'verify',//verifyMode
      label: t('components.VisitorDetailsDrawer.verify'),
    },
    {
      key: 'profilePhotoKey',
      label: '',
      type: 'photo'
    },

  ];
  const { data = {}, items = item } = props


  const [photoUrl, setPhotoUrl] = useState(null);
  const [urlLoading, setUrlLoading] = useState(false);
  useEffect(() => {
    setPhotoUrl('');
    const getPhoto = async (key) => {
      setUrlLoading(true)
      const { preSignedUrl = '' } = await download({ objectKey: key }) || {};
      setPhotoUrl(preSignedUrl);
      setUrlLoading(false)

    };
    if (data.profilePhotoKey) {
      getPhoto(data.profilePhotoKey);
    }
  }, [data]);



  const ContainerHeight = 400;
  const [passingRecordsList, setData] = useState([
    // { eventTime: '2023年11月2日' },
    // { eventTime: '2023年11月2日' },
    // { eventTime: '2023年11月2日' }
  ]);
  const [current, setCurrent] = useState(1);
  const appendData = async () => {

    const req = {
      pageNumber: current,
      pageSize: 20,
      event: 'doorOpen',
      deviceType: "acc",
      operator: data.visitorCode || '',
      startDateTime: data.visitDate === '-' ? '' : `${data.visitDate}:00`,
      endDateTime: data.visitEndDate === '-' ? '' : `${data.visitEndDate}:59`,
    };
    const res = await getTransactionList(req) || {};
    setCurrent(current + 1)
    setData(passingRecordsList.concat(res.list || []));

  };
  useEffect(() => {
    data.visitorCode && appendData();
  }, []);

  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      appendData();
    }
  };

  const Err = () => {
    return <div style={{
      width: '100px',
      height: '100px',
      border: '1px dashed #d9d9d9',
      background: '#fafafa',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <FileImageOutlined />

      <div >
        {t('common.empty.noPictures')}
      </div>

    </div>
  }

  const Photo = ({ src }) => {
    if (src) return <Image src={src} style={{
      width: '100px',
      height: '100px',
      objectFit: 'cover'
    }} />

    return <Err />

  }
  const labelColSpan = i18n.language === 'zh-CN' ? 6 : 24
  const contentColSpan = i18n.language === 'zh-CN' ? 18 : 24

  return (
    <Drawer
      className={`details-drawer ${props.className || ''}`}

      {...props}>
      {items.map((e) => {
        if (e.type === 'photo') {
          return <Row gutter={[24, 4]} justify="end" style={{ marginBottom: '24px' }}>
            <Col span={labelColSpan} ><strong>{e.label}</strong></Col >
            <Col span={contentColSpan} pull={1} >
              <div style={{
                width: '100px',
                height: '100px',
              }}>
                {/* {
                  urlLoading ?
                    <Spin /> :
                    photoUrl ?
                      <Image src={photoUrl} style={{
                        width: '100px',
                        height: '100px',
                        objectFit: 'cover'
                      }} /> :
                      <Err />
                } */}
                {urlLoading ? <Spin /> : <Photo src={photoUrl} />}
              </div>
            </Col >
          </Row>
        }
        if (e.type === 'list') {
          return <Row gutter={[24, 4]} justify="end" style={{ marginBottom: '24px' }}>
            <Col span={labelColSpan} ><strong>{e.label}</strong></Col >
            <Col span={contentColSpan} pull={1} >
              {passingRecordsList.length > 0 ? <List style={{ width: "280px" }}>
                <VirtualList
                  data={passingRecordsList}
                  height={ContainerHeight}
                  itemHeight={47}
                  itemKey="email"
                  onScroll={onScroll}
                >
                  {(item, index) => (
                    <List.Item key={index} style={{
                      // background: 'red',
                      position: 'relative',
                      top: '-10px',
                      paddingBottom: '0',
                      // paddingRight: '50px',
                      marginRight: '50px'
                    }}>
                      <span>{item.eventTime || '-'}</span>
                      <span>{item.verifyModeName || t('common.text.other')}</span>
                    </List.Item>
                  )}
                </VirtualList>
              </List> :
                '-'
              }
            </Col >
          </Row>
        }
        return <Row gutter={[24, 24]} justify="end" style={{ marginBottom: '24px' }}>
          <Col span={labelColSpan} >
            <strong>{e.label}</strong>
          </Col >
          {i18n.language === 'zh-CN' && <Col span={contentColSpan} pull={1} >{data[e.key] || '-'}</Col >}
          {i18n.language !== 'zh-CN' && <Col span={contentColSpan} pull={1} ><Input disabled={true} value={data[e.key] || ""} /></Col >}

        </Row>
      })}
    </Drawer>
  )
}
